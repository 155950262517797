<!--  -->
<template>
  <div id="Analysis">
    <!-- 左边 -->
    <div class="Analysis_right">
      <div class="testname">
        <p>{{ name }}</p>
        <div>
          <p>分数：{{ gradeall }}</p>
          <div>
            <p>难度</p>

            <el-rate v-model="difficultys" :colors="colors" disabled :size="40">
            </el-rate>
          </div>
        </div>
      </div>
      <el-button type="danger" @click="returnexam">返回成绩</el-button>
      <div class="Analysis_right_main">
        <div class="Analysis_right_main_title">答题卡</div>
        <!-- <ul>
          <li class="text_done">
            <i>
              <img src="../../assets/image/Icon-success.png" alt="" />
            </i>
            <div class="text_done_right">
              <p>已完成</p>
              <p>
                <span>{{ countfinish }}</span
                >道题
              </p>
            </div>
          </li>
          <li class="text_tag">
            <i>
              <img src="../../assets/image/Icon-info.png" alt="" />
            </i>
            <div class="text_done_right">
              <p>做标记</p>
              <p><span>5</span>道题</p>
            </div>
          </li>
          <li class="text_undone">
            <i>
              <img src="../../assets/image/Icon-warning.png" alt="" />
            </i>
            <div class="text_done_right">
              <p>未完成</p>
              <p>
                <span>{{ countnull }}</span
                >道题
              </p>
            </div>
          </li>
        </ul> -->
        <ul class="index__answerHint clearfix">
          <!-- <li><i class="index__yizuo"></i>已做</li>
          <li><i class="index__weizuo"></i>未做</li> -->
          <!-- <li>
            <i class="index__biaoji"></i>
            <i class="index__biaojixi"></i>标记
          </li> -->
        </ul>
        <div class="index__BigtiNumberBox">
          <div class="stem">【题目】</div>
          <ul>
            <li
              @click="clickCard(index)"
              v-for="(item, index) in allListData"
              :key="index"
              :class="[{ active: item.makeQuestion }, { act: item.topicBool }]"
            >
              {{ index + 1 }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 右边 -->
    <div class="Analysis_left">
      <!-- <div class="Analysis_left_top">
          <div>{{ classname }}</div>
          <div>
            <div>章节测试卷</div>
            <i class="el-icon-arrow-right"></i>
            <div>{{ name }}</div>
          </div>
        </div> -->
      <div class="Analysis_left_main">
        <div class="allList" v-for="(item, index) in allListData" :key="index">
          <div v-if="item.topicBool">
            <!-- 单选 -->
            <div
              class="singleChoice"
              v-if="item.question.type == 1 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div>
                  <span>{{ index + 1 }}</span>
                </div>
                <div>
                  <span v-if="item.question.defined == '' ? false : true"
                    >{{ item.question.defined }}</span
                  ><span v-else>{{
                    item.question.type == 1 ? "单选题" : ""
                  }}</span>
                </div>
                <div>({{ item.question.grade }}分)</div>
              </div>
              <div class="singleScreen">
                <!-- 文字 -->
                <div
                  class="singleWords"
                  v-if="item.question.stem_type == 1 ? true : false"
                >
                  <div class="stemBox">
                    <div class="singleStem" v-html="item.question.stem"></div>
                    <div
                      v-for="(item1, index1) in item.question.single_choices"
                      :key="index1"
                      class="singleSelectList"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 音频 -->
                <div
                  class="singleAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="audioBox">
                      <div class="btnback">
                        <div class="back" @click="clickSnd(-15)">
                          <img src="../../assets/image/kuaijin1.png" alt="" />
                        </div>
                        <div>{{ item.question.file.filename }}</div>
                        <div class="forward" @click="clickSnd(15)">
                          <img src="../../assets/image/kuaijin2.png" alt="" />
                        </div>
                      </div>
                      <audio controls controlslist="nodownload">
                        <source
                          :src="item.question.file.playpath"
                          type="audio/mp3"
                        />
                        您的浏览器不支持 HTML5 audio 标签。
                      </audio>
                    </div>

                    <div class="singleStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.single_choices"
                      :key="index1"
                      class="singleSelectList"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 视频 -->
                <div
                  class="singleVideo"
                  v-if="item.question.stem_type == 3 ? true : false"
                >
                  <div class="stemBox">
                    <video
                      :src="item.question.file.playpath"
                      controls="controls"
                    ></video>
                    <div class="singleStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.single_choices"
                      :key="index1"
                      class="singleSelectList"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 图片 -->
                <div
                  class="singleVideo"
                  v-if="item.question.stem_type == 4 ? true : false"
                >
                  <div class="stemBox">
                    <img
                      @click="clickShowImagePreview(index)"
                      :src="item.question.file.playpath"
                      v-if="item.question.file"
                      alt=""
                    />
                    <div class="singleStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.single_choices"
                      :key="index1"
                      class="singleSelectList"
                      @click="clickSingle(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <div class="analysis">
                  <!-- 答案 -->
                  <div class="analysisDa">
                    <div>
                      <div class="nide">
                        <div>你的答案：</div>
                        <div class="youanswer">
                          {{ item.answers }}
                          <span v-if="item.answers == null ? true : false"
                            >未作答</span
                          >

                          <i
                            class="iconfont icon-zhengque1"
                            style="color: #25c17c; font-size: 20px"
                            v-if="item.state == 1 ? true : false"
                          ></i
                          ><i
                            class="iconfont icon-cuowu"
                            style="color: #f4433c; font-size: 20px"
                            v-if="item.state == 0 ? true : false"
                          ></i>
                        </div>
                      </div>
                      <div class="daan">
                        <div class="firstdiv">参考答案：</div>
                        <div
                          class="single"
                          v-for="(item1, index1) in item.question
                            .single_choices"
                          :key="index1"
                          v-show="item1.yes == 1 ? true : false"
                        >
                          {{ listSum[index1] }}
                        </div>
                      </div>
                    </div>
                    <div class="jiexi">
                      <div>解析:</div>
                      <div v-html="item.question.analysis"></div>
                      <span v-if="item.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 多选题 -->
            <div
              class="multipleChoice"
              v-if="item.question.type == 2 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div>
                  <span>{{ index + 1 }}</span>
                </div>
                <div>
                  <span v-if="item.question.defined == '' ? false : true"
                    >({{ item.question.defined }})</span
                  ><span v-else>{{
                    item.question.type == 2 ? "多选题" : ""
                  }}</span>
                </div>
                <div>({{ item.question.grade }}分)</div>
              </div>
              <div class="singleScreen">
                <!-- 文字 -->
                <div
                  class="multipleWords"
                  v-if="item.question.stem_type == 1 ? true : false"
                >
                  <div class="stemBox">
                    <div class="multipleStem" v-html="item.question.stem"></div>
                    <div
                      v-for="(item1, index1) in item.question.multiple_choices"
                      :key="index1"
                      class="multipleSelectList"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 音频 -->
                <div
                  class="multipleAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="audioBox">
                      <div class="btnback">
                        <div class="back" @click="clickSnd(-15)">
                          <img src="../../assets/image/kuaijin1.png" alt="" />
                        </div>
                        <div>{{ item.question.file.filename }}</div>
                        <div class="forward" @click="clickSnd(15)">
                          <img src="../../assets/image/kuaijin2.png" alt="" />
                        </div>
                      </div>
                      <audio controls controlslist="nodownload">
                        <source
                          :src="item.question.file.playpath"
                          type="audio/mp3"
                        />
                        您的浏览器不支持 HTML5 audio 标签。
                      </audio>
                    </div>

                    <div class="multipleStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.multiple_choices"
                      :key="index1"
                      class="multipleSelectList"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 视频 -->
                <div
                  class="multipleVideo"
                  v-if="item.question.stem_type == 3 ? true : false"
                >
                  <div class="stemBox">
                    <video
                      :src="item.question.file.playpath"
                      controls="controls"
                    ></video>
                    <div class="multipleStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.multiple_choices"
                      :key="index1"
                      class="multipleSelectList"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 图片 -->
                <div
                  class="multipleVideo"
                  v-if="item.question.stem_type == 4 ? true : false"
                >
                  <div class="stemBox">
                    <img
                      @click="clickShowImagePreview(index)"
                      :src="item.question.file.playpath"
                      v-if="item.question.file"
                      alt=""
                    />
                    <div class="multipleStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.multiple_choices"
                      :key="index1"
                      class="multipleSelectList"
                      @click="clickMany(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <div class="analysis">
                  <div class="analysisDa">
                    <div>
                      <div class="nide">
                        <div>你的答案:</div>
                        <div class="youanswer">
                          {{ item.answers }}
                          <span v-if="item.answers == null ? true : false"
                            >未作答</span
                          >
                          <i
                            class="iconfont icon-zhengque1"
                            style="color: #25c17c; font-size: 20px"
                            v-if="item.state == 1 ? true : false"
                          ></i
                          ><i
                            class="iconfont icon-cuowu"
                            style="color: #f4433c; font-size: 20px"
                            v-if="item.state == 0 ? true : false"
                          ></i>
                        </div>
                      </div>
                      <div class="daad">
                        <div class="firstdiv">正确答案</div>
                        <span
                          class="single"
                          style="color: #25c17c"
                          v-for="(item1, index1) in item.question
                            .multiple_choices"
                          :key="index1"
                          v-show="item1.yes == 1 ? true : false"
                        >
                          {{ listSum[index1] }}
                        </span>
                      </div>
                    </div>
                     <div class="jiexi">
                    <div>解析:</div>
                    <div v-html="item.question.analysis"></div>
                  </div>
                  </div>
                 
                </div>
              </div>
            </div>
            <!-- 判断题 -->
            <div
              class="judgesChoice"
              v-if="item.question.type == 3 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div>
                  <span>{{ index + 1 }}</span>
                </div>
                <div>
                  <span v-if="item.question.defined == '' ? false : true"
                    >{{ item.question.defined }}</span
                  ><span v-else>{{
                    item.question.type == 3 ? "判断题" : ""
                  }}</span>
                </div>
                <div>({{ item.question.grade }}分)</div>
              </div>
              <div class="singleScreen">
                <!-- 文字 -->
                <div
                  class="judgesWords"
                  v-if="item.question.stem_type == 1 ? true : false"
                >
                  <div class="stemBox">
                    <div class="judgesStem" v-html="item.question.stem"></div>
                    <div
                      v-for="(item1, index1) in item.question.judges"
                      :key="index1"
                      class="judgesSelectList"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''"></div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 音频 -->
                <div
                  class="judgesAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="audioBox">
                      <div class="btnback">
                        <div class="back" @click="clickSnd(-15)">
                          <img src="../../assets/image/kuaijin1.png" alt="" />
                        </div>
                        <div>{{ item.question.file.filename }}</div>
                        <div class="forward" @click="clickSnd(15)">
                          <img src="../../assets/image/kuaijin2.png" alt="" />
                        </div>
                      </div>
                      <audio controls controlslist="nodownload">
                        <source
                          :src="item.question.file.playpath"
                          type="audio/mp3"
                        />
                        您的浏览器不支持 HTML5 audio 标签。
                      </audio>
                    </div>

                    <div class="judgesStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.judges"
                      :key="index1"
                      class="judgesSelectList"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''"></div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 视频 -->
                <div
                  class="judgesVideo"
                  v-if="item.question.stem_type == 3 ? true : false"
                >
                  <div class="stemBox">
                    <video
                      :src="item.question.file.playpath"
                      controls="controls"
                    ></video>
                    <div class="judgesStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.judges"
                      :key="index1"
                      class="judgesSelectList"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''"></div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <div class="analysis">
                  <div class="analysisDa">
                    <div>
                      <div class="nide">
                        <div>你的答案</div>
                        <div class="youanswer">
                          {{ item.answers }}
                          <span v-if="item.answers == null ? true : false"
                            >未作答</span
                          >
                          <i
                            class="iconfont icon-zhengque1"
                            style="color: #25c17c; font-size: 20px"
                            v-if="item.state == 1 ? true : false"
                          ></i
                          ><i
                            class="iconfont icon-cuowu"
                            style="color: #f4433c; font-size: 20px"
                            v-if="item.state == 0 ? true : false"
                          ></i>
                        </div>
                      </div>
                      <div class="daan">
                        <div>参考答案</div>
                        <span
                          class="refrance"
                          style="color: #25c17c"
                          v-for="(item1, index1) in item.question.judges"
                          :key="index1"
                          v-show="item1.yes == 1 ? true : false"
                        >
                          {{ item1.answer }}
                        </span>
                      </div>
                    </div>
                     <div class="jiexi">
                    <div>解析:</div>
                    <div v-html="item.question.analysis"></div>
                  </div>
                  </div>
                 
                </div>
              </div>
            </div>
            <!-- 多元填空 -->
            <div
              class="blankChoice"
              v-if="item.question.type == 6 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div>
                  <span>{{ index + 1 }}</span>
                </div>
                <div>
                  <span v-if="item.question.defined == '' ? false : true"
                    >({{ item.question.defined }})</span
                  ><span v-else>{{
                    item.question.type == 6 ? "多元填空" : ""
                  }}</span>
                </div>
                <div>({{ item.question.grade }}分)</div>
              </div>
              <div class="singleScreen">
                <!-- 文字 -->
                <div
                  class="blankWords"
                  v-if="item.question.stem_type == 1 ? true : false"
                >
                  <div class="stemBox">
                    <div class="blankStem" v-html="item.question.stem"></div>
                  </div>
                  <div class="analysis">
                    <div class="daan">
                      <div
                        v-for="(item1, index1) in item.question.multiple_clozes"
                        :key="index1"
                      >
                        <div>
                          <div>
                            第({{ index1 + 1 }})空 ({{ item1.grade }}分)
                          </div>

                          <div class="blankChoice-answer">
                            <div class="youanswer">
                              你的答案:
                              <span>{{ item.answers[index1] }}</span>
                              <span
                                v-if="
                                  item.answers[index1] == undefined ||
                                  item.answers[index1] == ''
                                    ? true
                                    : false
                                "
                                >未作答</span
                              >
                              <i
                                class="iconfont icon-zhengque1"
                                style="color: #25c17c; font-size: 20px"
                                v-if="item1.succ"
                              ></i
                              ><i
                                class="iconfont icon-cuowu"
                                style="color: #f4433c; font-size: 20px"
                                v-if="!item1.succ"
                              ></i>
                            </div>

                            <div class="reference1 single">
                               参考答案:
                               <div>
                                  <div
                                  v-for="(item2, index2) in item1.answers"
                                  :key="index2"
                                  >{{ item2.answer }}</div
                                >
                               </div>
                            </div>
                          </div>
                        </div>

                        <!-- </div> -->
                        <div class="resolve">
                          <span>小题解析：</span>{{ item1.analysis
                          }}<span v-if="item1.analysis == '' ? true : false"
                            >暂无</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="bigjiexi">
                      <div>阅读解析:</div>
                      <div v-html="item.question.analysis"></div>
                      <span v-if="item.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 音频 -->
                <div
                  class="blankAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="audioBox">
                      <div class="btnback">
                        <div class="back" @click="clickSnd(-15)">
                          <img src="../../assets/image/kuaijin1.png" alt="" />
                        </div>
                        <div>{{ item.question.file.filename }}</div>
                        <div class="forward" @click="clickSnd(15)">
                          <img src="../../assets/image/kuaijin2.png" alt="" />
                        </div>
                      </div>
                      <audio controls controlslist="nodownload">
                        <source
                          :src="item.question.file.playpath"
                          type="audio/mp3"
                        />
                        您的浏览器不支持 HTML5 audio 标签。
                      </audio>
                    </div>
                    <div class="blankStem" v-html="item.question.stem"></div>
                  </div>
                  <div class="analysis">
                    <div class="daan">
                      <div
                        v-for="(item1, index1) in item.question.multiple_clozes"
                        :key="index1"
                      >
                        <div>第({{ index1 + 1 }})空 ({{ item1.grade }}分)</div>

                        <div class="youanswer">
                          你的答案:
                          <span>{{ item.answers[index1] }}</span>
                          <span
                            v-if="
                              item.answers[index1] == undefined ||
                              item.answers[index1] == ''
                                ? true
                                : false
                            "
                            >未作答</span
                          >
                          <i
                            class="iconfont icon-zhengque1"
                            style="color: #25c17c; font-size: 20px"
                            v-if="item1.succ"
                          ></i
                          ><i
                            class="iconfont icon-cuowu"
                            style="color: #f4433c; font-size: 20px"
                            v-if="!item1.succ"
                          ></i>
                        </div>

                        <div class="reference">
                           参考答案:
                               <div>
                                  <div
                                  v-for="(item2, index2) in item1.answers"
                                  :key="index2"
                                  >{{ item2.answer }}</div
                                >
                               </div>
                        </div>

                        <!-- </div> -->
                        <div class="jiexi">
                          小题解析：{{ item1.analysis
                          }}<span v-if="item1.analysis == '' ? true : false"
                            >暂无</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="bigjiexi">
                      <div>阅读解析:</div>
                      <div v-html="item.question.analysis"></div>
                      <span v-if="item.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 图片 -->
                <div
                  class="blankWords"
                  v-if="item.question.stem_type == 4 ? true : false"
                >
                  <div class="stemBox">
                      <img
                      @click="clickShowImagePreview(index)"
                     :src="item.question.file.playpath"
                     v-if="item.question.file"
                      alt=""
                    />
                    <div class="blankStem" v-html="item.question.stem"></div>
                  </div>
                  <div class="analysis">
                    <div class="daan">
                      <div
                        v-for="(item1, index1) in item.question.multiple_clozes"
                        :key="index1"
                      >
                        <div>
                          <div>
                            第({{ index1 + 1 }})空 ({{ item1.grade }}分)
                          </div>

                          <div class="blankChoice-answer">
                            <div class="youanswer">
                              你的答案:
                              <span>{{ item.answers[index1] }}</span>
                              <span
                                v-if="
                                  item.answers[index1] == undefined ||
                                  item.answers[index1] == ''
                                    ? true
                                    : false
                                "
                                >未作答</span
                              >
                              <i
                                class="iconfont icon-zhengque1"
                                style="color: #25c17c; font-size: 20px"
                                v-if="item1.succ"
                              ></i
                              ><i
                                class="iconfont icon-cuowu"
                                style="color: #f4433c; font-size: 20px"
                                v-if="!item1.succ"
                              ></i>
                            </div>

                            <div class="reference single">
                              参考答案:
                               <div>
                                  <div
                                  v-for="(item2, index2) in item1.answers"
                                  :key="index2"
                                  >{{ item2.answer }}</div
                                >
                               </div>
                            </div>
                          </div>
                        </div>

                        <!-- </div> -->
                        <div class="resolve">
                          <span>小题解析：</span>{{ item1.analysis
                          }}<span v-if="item1.analysis == '' ? true : false"
                            >暂无</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="bigjiexi">
                      <div>阅读解析:</div>
                      <div v-html="item.question.analysis"></div>
                      <span v-if="item.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 阅读题 -->
            <div
              class="readingChoice"
              v-if="item.question.type == 9 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div>
                  <span>{{ index + 1 }}</span>
                </div>
                <div>
                  <span v-if="item.question.defined == '' ? false : true"
                    >({{ item.question.defined }})</span
                  ><span v-else>{{
                    item.question.type == 9 ? "阅读题" : ""
                  }}</span>
                </div>
                <div>({{ item.question.grade }}分)</div>
                <div class="card">
                  <div class="allListLeftborder"></div>
                  <div>答题卡</div>
                </div>
              </div>
              <div class="singleScreen">
                <!-- 文字 -->
                <div
                  class="readingWords"
                  v-if="item.question.stem_type == 1 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <div
                        class="readingStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <div
                        class="readTopic"
                        v-for="(item1, index1) in item.question.reads"
                        :key="index1"
                      >
                        <div class="readingStem">
                          {{ index1 + 1 }}.{{
                            item1.question.stem == "null"
                              ? ""
                              : item1.question.stem
                          }}<span>({{ item1.question.grade }}分)</span>
                        </div>

                        <div
                          class="readingSelectList"
                          v-for="(item2, index2) in item1.question
                            .single_choices"
                          :key="index2"
                        >
                          <div :class="item2.isSelect ? 'isSelect' : ''">
                            {{ listSum[index2] }}
                          </div>
                          <p v-html="item2.answer"></p>
                        </div>
                        <div>
                          <!-- 答案 -->

                          <div class="readingjiexi">
                            <div class="readingstyle">
                              <div class="nide">
                                <div style="color: black">你的答案</div>
                                <div class="youanswer">
                                  {{ item1.answers }}
                                  <span
                                    v-if="item1.answers == null ? true : false"
                                    >未作答</span
                                  >
                                  <i
                                    class="iconfont icon-zhengque1"
                                    style="color: #25c17c; font-size: 20px"
                                    v-if="item1.state == 1 ? true : false"
                                  ></i
                                  ><i
                                    class="iconfont icon-cuowu"
                                    style="color: #f4433c; font-size: 20px"
                                    v-if="item1.state == 0 ? true : false"
                                  ></i>
                                </div>
                              </div>
                              <div class="daan">
                                <div class="firstdiv" style="color: black">
                                  正确答案
                                </div>
                                <div
                                  class="reference"
                                  v-for="(item3, index3) in item1.question
                                    .single_choices"
                                  :key="index3"
                                  v-show="item3.yes == 1 ? true : false"
                                >
                                  {{ listSum[index3] }}
                                </div>
                              </div>
                            </div>

                            <div class="sortjiexi">
                              <span>解析:</span>

                              <div v-html="item1.question.analysis"></div>
                              <span
                                v-if="
                                  item1.question.analysis == '' ? true : false
                                "
                                >暂无</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="analysis">
                        <div class="jiexi">
                          <div>阅读解析:</div>
                          <div v-html="item.question.analysis"></div>
                          <span
                            v-if="item.question.analysis == '' ? true : false"
                            >暂无</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 音频 -->
                <div
                  class="readingAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <div class="audioBox">
                        <div class="btnback">
                          <div class="back" @click="clickSnd(-15)">
                            <img src="../../assets/image/kuaijin1.png" alt="" />
                          </div>
                          <div>{{ item.question.file.filename }}</div>
                          <div class="forward" @click="clickSnd(15)">
                            <img src="../../assets/image/kuaijin2.png" alt="" />
                          </div>
                        </div>
                        <audio controls controlslist="nodownload">
                          <source
                            :src="item.question.file.playpath"
                            type="audio/mp3"
                          />
                          您的浏览器不支持 HTML5 audio 标签。
                        </audio>
                      </div>

                      <div
                        class="readingStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <div
                        class="readTopic"
                        v-for="(item1, index1) in item.question.reads"
                        :key="index1"
                      >
                        <div class="readingStem">
                          {{ index1 + 1 }}.{{
                            item1.question.stem == "null"
                              ? ""
                              : item1.question.stem
                          }}<span>({{ item1.question.grade }}分)</span>
                        </div>

                        <div
                          class="readingSelectList"
                          v-for="(item2, index2) in item1.question
                            .single_choices"
                          :key="index2"
                        >
                          <div :class="item2.isSelect ? 'isSelect' : ''">
                            {{ listSum[index2] }}
                          </div>
                          <p v-html="item2.answer"></p>
                        </div>
                        <div>
                          <!-- 答案 -->

                          <div class="readingjiexi">
                            <div class="readingstyle">
                              <div class="nide">
                                <div style="color: black">你的答案</div>
                                <div class="youanswer">
                                  {{ item1.answers }}
                                  <span
                                    v-if="item1.answers == null ? true : false"
                                    >未作答</span
                                  >
                                  <i
                                    class="iconfont icon-zhengque1"
                                    style="color: #25c17c; font-size: 20px"
                                    v-if="item1.state == 1 ? true : false"
                                  ></i
                                  ><i
                                    class="iconfont icon-cuowu"
                                    style="color: #f4433c; font-size: 20px"
                                    v-if="item1.state == 0 ? true : false"
                                  ></i>
                                </div>
                              </div>
                              <div class="daan">
                                <div class="firstdiv" style="color: black">
                                  正确答案
                                </div>
                                <div
                                  class="reference"
                                  v-for="(item3, index3) in item1.question
                                    .single_choices"
                                  :key="index3"
                                  v-show="item3.yes == 1 ? true : false"
                                >
                                  {{ listSum[index3] }}
                                </div>
                              </div>
                            </div>

                            <div class="sortjiexi">
                              <span>解析：</span>

                              <div v-html="item1.question.analysis"></div>
                              <span
                                v-if="
                                  item1.question.analysis == '' ? true : false
                                "
                                >暂无</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="analysis">
                        <div class="jiexi">
                          <div>阅读解析:</div>
                          <div v-html="item.question.analysis"></div>
                          <span
                            v-if="item.question.analysis == '' ? true : false"
                            >暂无</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 视频 -->
                <div
                  class="readingVideo"
                  v-if="item.question.stem_type == 3 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <video
                        :src="item.question.file.playpath"
                        controls="controls"
                      ></video>
                      <div
                        class="readingStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <div
                        class="readTopic"
                        v-for="(item1, index1) in item.question.reads"
                        :key="index1"
                      >
                        <div class="readingStem">
                          {{ index1 + 1 }}.{{
                            item1.question.stem == "null"
                              ? ""
                              : item1.question.stem
                          }}<span>({{ item1.question.grade }}分)</span>
                        </div>

                        <div
                          class="readingSelectList"
                          v-for="(item2, index2) in item1.question
                            .single_choices"
                          :key="index2"
                        >
                          <div :class="item2.isSelect ? 'isSelect' : ''">
                            {{ listSum[index2] }}
                          </div>
                          <p v-html="item2.answer"></p>
                        </div>
                        <div>
                          <!-- 答案 -->

                          <div class="readingjiexi">
                            <div class="readingstyle">
                              <div class="nide">
                                <div style="color: black">你的答案</div>
                                <div class="youanswer">
                                  {{ item1.answers }}
                                  <span
                                    v-if="item1.answers == null ? true : false"
                                    >未作答</span
                                  >
                                  <i
                                    class="iconfont icon-zhengque1"
                                    style="color: #25c17c; font-size: 20px"
                                    v-if="item1.state == 1 ? true : false"
                                  ></i
                                  ><i
                                    class="iconfont icon-cuowu"
                                    style="color: #f4433c; font-size: 20px"
                                    v-if="item1.state == 0 ? true : false"
                                  ></i>
                                </div>
                              </div>
                              <div class="daan">
                                <div class="firstdiv" style="color: black">
                                  正确答案
                                </div>
                                <div
                                  class="reference"
                                  v-for="(item3, index3) in item1.question
                                    .single_choices"
                                  :key="index3"
                                  v-show="item3.yes == 1 ? true : false"
                                >
                                  {{ listSum[index3] }}
                                </div>
                              </div>
                            </div>

                            <div class="sortjiexi">
                              <span>解析:</span>

                              <div v-html="item1.question.analysis"></div>
                              <span
                                v-if="
                                  item1.question.analysis == '' ? true : false
                                "
                                >暂无</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="analysis">
                        <div class="jiexi">
                          <div>阅读解析:</div>
                          <div v-html="item.question.analysis"></div>
                          <span
                            v-if="item.question.analysis == '' ? true : false"
                            >暂无</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 图片 -->
                <div
                  class="readingVideo"
                  v-if="item.question.stem_type == 4 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <img
                        @click="clickShowImagePreview(index)"
                        :src="item.question.file.playpath"
                        v-if="item.question.file"
                        alt=""
                      />
                      <div
                        class="readingStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <div
                        class="readTopic"
                        v-for="(item1, index1) in item.question.reads"
                        :key="index1"
                      >
                        <div class="readingStem">
                          {{ index1 + 1 }}.{{
                            item1.question.stem == "null"
                              ? ""
                              : item1.question.stem
                          }}<span>({{ item1.question.grade }}分)</span>
                        </div>

                        <div
                          class="readingSelectList"
                          v-for="(item2, index2) in item1.question
                            .single_choices"
                          :key="index2"
                        >
                          <div :class="item2.isSelect ? 'isSelect' : ''">
                            {{ listSum[index2] }}
                          </div>
                          <p v-html="item2.answer"></p>
                        </div>
                        <div>
                          <!-- 答案 -->

                          <div class="readingjiexi">
                            <div class="readingstyle">
                              <div class="nide">
                                <div style="color: black">你的答案</div>
                                <div class="youanswer">
                                  {{ item1.answers }}
                                  <span
                                    v-if="item1.answers == null ? true : false"
                                    >未作答</span
                                  >
                                  <i
                                    class="iconfont icon-zhengque1"
                                    style="color: #25c17c; font-size: 20px"
                                    v-if="item1.state == 1 ? true : false"
                                  ></i
                                  ><i
                                    class="iconfont icon-cuowu"
                                    style="color: #f4433c; font-size: 20px"
                                    v-if="item1.state == 0 ? true : false"
                                  ></i>
                                </div>
                              </div>
                              <div class="daan">
                                <div class="firstdiv" style="color: black">
                                  正确答案
                                </div>
                                <div
                                  class="reference"
                                  v-for="(item3, index3) in item1.question
                                    .single_choices"
                                  :key="index3"
                                  v-show="item3.yes == 1 ? true : false"
                                >
                                  {{ listSum[index3] }}
                                </div>
                              </div>
                            </div>

                            <div class="sortjiexi">
                              <span>解析:</span>

                              <div v-html="item1.question.analysis"></div>
                              <span
                                v-if="
                                  item1.question.analysis == '' ? true : false
                                "
                                >暂无</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="analysis">
                        <div class="jiexi">
                          <div>阅读解析:</div>
                          <div v-html="item.question.analysis"></div>
                          <span
                            v-if="item.question.analysis == '' ? true : false"
                            >暂无</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 问答题 -->
            <div
              class="questionsChoice"
              v-if="item.question.type == 4 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div>
                  <span>{{ index + 1 }}</span>
                </div>
                <div>
                  <span v-if="item.question.defined == '' ? false : true"
                    >({{ item.question.defined }})</span
                  ><span v-else>{{
                    item.question.type == 4 ? "问答题" : ""
                  }}</span>
                </div>
                <div>({{ item.question.grade }}分)</div>
                <div class="card">
                  <div class="allListLeftborder"></div>
                  <div>答题卡</div>
                </div>
              </div>
              <div class="singleScreen">
                <!-- 文字 -->
                <div
                  class="questionsWords"
                  v-if="item.question.stem_type == 1 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <div
                        class="questionsStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <!-- <textarea
                        v-model="item.modelValue"
                        type="text"
                        placeholder="请输入答案"
                      /> -->
                      <div class="analysis">
                        <div class="nide-answer">
                          <div>
                            <div>你的答案:</div>
                            <div class="youanswer">
                              <span
                                class="fontcolor"
                                style="word-break: break-all"
                                >{{ item.answers }}
                              </span>
                              <span v-if="item.answers == null ? true : false"
                                >未作答</span
                              >
                            </div>
                          </div>

                          <div>
                            <div>解析:</div>
                            <div
                              class="solution"
                              v-html="item.question.analysis"
                            ></div>
                            <span
                              v-if="item.question.analysis == '' ? true : false"
                              >暂无</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 音频 -->
                <div
                  class="questionsAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <div class="audioBox">
                        <div class="btnback">
                          <div class="back" @click="clickSnd(-15)">
                            <img src="../../assets/image/kuaijin1.png" alt="" />
                          </div>
                          <div>{{ item.question.file.filename }}</div>
                          <div class="forward" @click="clickSnd(15)">
                            <img src="../../assets/image/kuaijin2.png" alt="" />
                          </div>
                        </div>
                        <audio controls controlslist="nodownload">
                          <source
                            :src="item.question.file.playpath"
                            type="audio/mp3"
                          />
                          您的浏览器不支持 HTML5 audio 标签。
                        </audio>
                      </div>

                      <div
                        class="questionsStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <!-- <textarea
                        v-model="item.modelValue"
                        type="text"
                        placeholder="请输入答案"
                      /> -->
                      <div class="analysis">
                        <div class="nide-answer">
                          <div>
                            <div>你的答案</div>
                            <div class="youanswer">
                              <span
                                class="fontcolor"
                                style="word-break: break-all"
                                >{{ item.answers }}
                              </span>
                              <span v-if="item.answers == null ? true : false"
                                >未作答</span
                              >
                            </div>
                          </div>

                          <div>
                            <div>解析:</div>
                            <div
                              class="solution"
                              v-html="item.question.analysis"
                            ></div>
                            <span
                              v-if="item.question.analysis == '' ? true : false"
                              >暂无</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 视频 -->
                <div
                  class="questionsVideo"
                  v-if="item.question.stem_type == 3 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <video
                        :src="item.question.file.playpath"
                        controls="controls"
                      ></video>
                      <div
                        class="questionsStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <!-- <textarea
                        v-model="item.modelValue"
                        type="text"
                        placeholder="请输入答案"
                      /> -->
                      <div class="analysis">
                        <div class="nide-answer">
                          <div>
                            <div>你的答案</div>
                            <div class="youanswer">
                              <span
                                class="fontcolor"
                                style="word-break: break-all"
                                >{{ item.answers }}
                              </span>
                              <span v-if="item.answers == null ? true : false"
                                >未作答</span
                              >
                            </div>
                          </div>

                          <div>
                            <div>解析:</div>
                            <div
                              class="solution"
                              v-html="item.question.analysis"
                            ></div>
                            <span
                              v-if="item.question.analysis == '' ? true : false"
                              >暂无</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 图片 -->
                <div
                  class="questionsVideo"
                  v-if="item.question.stem_type == 4 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <img
                        @click="clickShowImagePreview(index)"
                        :src="item.question.file.playpath"
                        v-if="item.question.file"
                        alt=""
                      />
                      <div
                        class="questionsStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <!-- <textarea
                        v-model="item.modelValue"
                        type="text"
                        placeholder="请输入答案"
                      /> -->
                      <div class="analysis">
                        <div class="nide-answer">
                          <div>
                            <div>你的答案</div>
                            <div class="youanswer">
                              <span
                                class="fontcolor"
                                style="word-break: break-all"
                                >{{ item.answers }}
                              </span>
                              <span v-if="item.answers == null ? true : false"
                                >未作答</span
                              >
                            </div>
                          </div>

                          <div>
                            <div>解析:</div>
                            <div
                              class="solution"
                              v-html="item.question.analysis"
                            ></div>
                            <span
                              v-if="item.question.analysis == '' ? true : false"
                              >暂无</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 听力题 -->
            <div
              class="listeningChoice"
              v-if="item.question.type == 10 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div>
                  <span>{{ index + 1 }}</span>
                </div>
                <div>
                  <span v-if="item.question.defined == '' ? false : true"
                    >({{ item.question.defined }})</span
                  ><span v-else>{{
                    item.question.type == 10 ? "听力题" : ""
                  }}</span>
                </div>
                <div>({{ item.question.grade }}分)</div>
                <div class="card">
                  <div class="allListLeftborder"></div>
                  <div>答题卡</div>
                </div>
              </div>
              <div class="singleScreen">
                <!-- 音频 -->
                <div
                  class="listeningAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <div class="audioBox">
                        <div class="btnback">
                          <div class="back" @click="clickSnd(-15)">
                            <img src="../../assets/image/kuaijin1.png" alt="" />
                          </div>
                          <div>{{ item.question.file.filename }}</div>
                          <div class="forward" @click="clickSnd(15)">
                            <img src="../../assets/image/kuaijin2.png" alt="" />
                          </div>
                        </div>
                        <audio controls controlslist="nodownload">
                          <source
                            :src="item.question.file.playpath"
                            type="audio/mp3"
                          />
                          您的浏览器不支持 HTML5 audio 标签。
                        </audio>
                      </div>

                      <div
                        class="listeningStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <!-- <textarea
                        v-model="item.modelValue"
                        type="text"
                        placeholder="请输入答案"
                      /> -->
                      <div class="analysis">
                        <div class="nide-answer">
                          <div>
                            <div>你的答案:</div>
                            <div class="youanswer">
                              <span
                                class="fontcolor"
                                style="word-break: break-all"
                                >{{ item.answers }}
                              </span>
                              <span v-if="item.answers == null ? true : false"
                                >未作答</span
                              >
                            </div>
                          </div>

                          <div>
                            <div>解析:</div>
                            <div
                              class="solution"
                              v-html="item.question.analysis"
                              style="line-height: 30px"
                            ></div>
                            <span
                              v-if="item.question.analysis == '' ? true : false"
                              >暂无</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Analysis_left_bottom">
        <div class="button">
          <div>
            <el-button
              type="primary"
              :disabled="!upperBool"
              @click="clickShow(-1)"
            >
              <span><img src="@/assets/image/iconleft.png" alt="" /></span
              >上一题</el-button
            >
          </div>
          <div>
            <el-button>解析</el-button>
          </div>

          <div>
            <el-button
              type="primary"
              :disabled="!downBool"
              @click="clickShow(1)"
              >下一题<span
                ><img src="@/assets/image/iconright.png" alt="" /></span
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import $ from "jquery";
import { ImagePreview } from "vant";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  head() {
    return {
      title: this.name,
    };
  },
  data() {
    //这里存放数据
    return {
      name: "", //试卷名
      gradeall: "", //试卷分数
      classname: "", //试卷分类名
      time: 0, //倒计时
      question: {}, //创建的试卷
      allObj: {}, //所有数据
      allListData: [], //题型列表
      redList: [], //阅读小题
      listSum: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      defaultIndex: 0,
      upperBool: false, //上一题
      downBool: true, //下一题
      numbertest: 0, //总数
      countnull: "", //未完成
      countfinish: "", //已完成
      difficultys: null,
      colors: ["#F7BA2A", "#F7BA2A", "#FF9900"],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    allListData: {
      handler() {
        if (this.defaultIndex == this.allListData.length - 1) {
          this.downBool = false;
        }
      },
      deep: true,
    },
  },
  //方法集合
  methods: {
    clickShowImagePreview(index) {
      // 预览
      let _this = this;
      ImagePreview({
        images: [_this.allListData[index].question.file.playpath],
        closeable: true,
      });
    },
    clickSnd(time) {
      var audio = document.getElementsByTagName("audio");
      audio[0].currentTime += time;
      audio[0].play();
    },
    clickShow(val) {
      //下一题上一题
      let _this = this;
      $("#Analysis audio").each((i, v) => {
        v.pause();
      });
      $("#Analysis video").each((i, v) => {
        v.pause();
      });
      _this.defaultIndex += val;
      _this.allListData.forEach((item, index) => {
        if (_this.defaultIndex == index) {
          _this.$delete(_this.allListData[index], "topicBool");
          _this.$set(_this.allListData[index], "topicBool", true);
        } else {
          _this.$delete(_this.allListData[index], "topicBool");
          _this.$set(_this.allListData[index], "topicBool", false);
        }
      });
      if (
        _this.defaultIndex > 0 &&
        _this.defaultIndex < _this.allListData.length - 1
      ) {
        _this.downBool = true; //激活下一题
        _this.upperBool = true; //激活上一题
      } else if (_this.defaultIndex == 0) {
        _this.downBool = true; //激活下一题
        _this.upperBool = false; //去掉上一题
      } else if (_this.defaultIndex == _this.allListData.length - 1) {
        _this.downBool = false; //去掉下一题
        _this.upperBool = true; //激活上一题
      }
    },
    clickCard(index) {
      // 点击答题卡
      let _this = this;
      _this.defaultIndex = index;
      _this.allListData.forEach((v) => {
        v.topicBool = false;
      });

      _this.allListData[index].topicBool = true;
      _this.$delete(_this.allListData[index], "topicBool");
      _this.$set(_this.allListData[index], "topicBool", true);
      if (
        _this.defaultIndex > 0 &&
        _this.defaultIndex < _this.allListData.length - 1
      ) {
        _this.downBool = true; //激活下一题
        _this.upperBool = true; //激活上一题
      } else if (_this.defaultIndex == 0) {
        _this.downBool = true; //激活下一题
        _this.upperBool = false; //去掉上一题
      } else if (_this.defaultIndex == _this.allListData.length - 1) {
        _this.downBool = false; //去掉下一题
        _this.upperBool = true; //激活上一题
      }
    },
    records() {
      // 创建试卷
      let _this = this;
      //获取所有数据

      _this.$api.shows(_this.question).then((res) => {
        _this.gradeall = res.data.question_class_record.gradeall;
        _this.numbertest = res.data.question_class_record.items.length;
        _this.difficultys =
          res.data.question_class_record.question_class.difficultys;
        res.data.question_class_record.items.filter((item) => {
          switch (item.state) {
            case 0:
              break;

            default:
              break;
          }
        });
        _this.allObj = res.data.question_class_record; //所有数据
        _this.time = _this.allObj.question_class.durations * 60 * 1000; //倒计时
        _this.allListData = _this.allObj.items.filter(
          (item) => item.question_id_son == null
        ); //题型列表
        _this.redList = _this.allObj.items.filter(
          (item) => item.question_id_son != null
        ); //阅读小题

        // 按照question_id_son分类
        let b = _this.redList.reduce(
          (r, x) => (
            (r[x.question_id_son] || (r[x.question_id_son] = [])).push(x), r
          ),
          {}
        );
        let c = Object.keys(b).map((x) => b[x]);
        //吧单选中的阅读题替换阅读题
        c.forEach((v, i) => {
          v.forEach((item, index) => {
            _this.allListData.forEach((v1, i1) => {
              if (v1.question.type == 9) {
                if (_this.allListData[i1].question_id == item.question_id_son) {
                  v1.question.reads = v;
                }
              }
            });
          });
        });
        _this.allListData.forEach((item, index) => {
          item.topicBool = false; //是否显示题
          item.makeQuestion = false; //是否做题--答题卡
          if (item.question.type == 1) {
            // 单选
            item.question.single_choices.forEach((item1, index1) => {
              item1.isSelect = false;
              if (item.answers == _this.listSum[index1]) {
                //做的题
                item1.isSelect = true; //你的答案
              }
            });
          } else if (item.question.type == 2) {
            // 多选
            item.question.multiple_choices.forEach((item1, index1) => {
              item1.isSelect = false;
              if (item.answers) {
                item.answers.split("").forEach((item2, index2) => {
                  if (item2 == _this.listSum[index1]) {
                    //做的题
                    item1.isSelect = true;
                  }
                });
              }
            });
          } else if (item.question.type == 3) {
            //判断题
            item.question.judges.forEach((item1) => {
              item1.isSelect = false;
              if (item.answers == item1.answer) {
                //做的题
                item1.isSelect = true;
              }
            });
          } else if (item.question.type == 9) {
            //阅读题
            item.question.reads.forEach((item1) => {
              item1.makeQuestion = false; //是否做题
              item1.question.single_choices.forEach((item2, index2) => {
                item2.isSelect = false;
                if (item1.answers == _this.listSum[index2]) {
                  //做的题
                  item2.isSelect = true;
                }
              });
            });
          } else if (item.question.type == 6) {
            // 多元
            item.question.stem = item.question.stem.replace(
              /____/g,
              `<input  placeholder='点击答题'  />`
            );
            var n = item.question.stem.split("input").length - 1;
            // item.input = [];
            // for (let index = 0; index < n; index++) {
            //   item.input[index] = "";
            // }
            console.log(item.answers);
            if (item.answers) {
              item.answers = item.answers.substring(0, item.answers.length - 1); //截取最后一个+
              item.answers = item.answers.split("+"); //转成数组
console.log(item.answers);
              item.question.multiple_clozes.forEach((item1) => {
                item1.anss = []; //答案
                item1.answers.forEach((item2) => {
                  item1.anss.push(item2.answer);
                });
              });
              // item.anss.includes(v.answers[index])

              item.question.multiple_clozes.forEach((item1, index1) => {
                if (item.answers[index1] != undefined) {
                  if (item1.anss.includes(item.answers[index1])) {
                    item1.succ = true;
                  } else {
                    item1.succ = false;
                  }
                }
              });
            } else {
              item.answers = [];
            }
          } else if (item.question.type == 4) {
            //问答题text框
            item.modelValue = "";
            item.modelScore = "";
          } else if (item.question.type == 10) {
            //听力题text框
            item.modelValue = "";
            item.modelScore = "";
          }
        });
        _this.allListData[0].topicBool = true;
         _this.loading.close();
      });
    },
    //返回成绩
    returnexam() {
      this.$router.push("/Index/Answersheet");
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    let _this = this;
     _this.loading = _this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    _this.name = localStorage.name; //试卷名
    _this.question = _this.$store.state.question; //创建的试卷
    _this.records();
    _this.$api.analysis(_this.question).then((res) => {
      _this.countfinish = res.data.count - res.data.countnull;
      _this.countnull = res.data.countnull;
     
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.$nextTick(()=>{
    //   // $('input')
    // })
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {
    this.$nextTick(() => {
      $(".bigjiexi").on("click", ".thumbnail-i", function () {
        ImagePreview({
          images: [$(this).attr("src")],
          closeable: true,
        });
      });
      $(".jiexi").on("click", ".thumbnail-i", function () {
        ImagePreview({
          images: [$(this).attr("src")],
          closeable: true,
        });
      });
      $(".sortjiexi").on("click", ".thumbnail-i", function () {
        ImagePreview({
          images: [$(this).attr("src")],
          closeable: true,
        });
      });
      $(".readingjiexi").on("click", ".thumbnail-i", function () {
        ImagePreview({
          images: [$(this).attr("src")],
          closeable: true,
        });
      });
       $(".stemBox").on("click", ".thumbnail-i", function () {
        ImagePreview({
          images: [$(this).attr("src")],
          closeable: true,
        });
      });
    });
  },
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
@bgcolor: #de3833;

#Analysis {
  /deep/font {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
      Segoe UI, Arial, Roboto, "PingFang SC", miui, "Hiragino Sans GB",
      "Microsoft Yahei", sans-serif;
  }
  /deep/.el-dialog__wrapper {
    .el-dialog {
      background: #fff;
      height: 350px;
      padding-top: 10px;

      //  padding-bottom: 110px;
      .el-dialog__header {
        height: 180px;
        background: url("../../assets/image/jiaojuan.png") no-repeat;
        background-size: 100%;
      }
      .el-dialog__body {
        text-align: center;
        padding: 20px 0;
        font-size: 18px;
      }
      .el-dialog__footer {
        .dialog-footer {
          .el-button {
            &:first-child {
              background: @bgcolor;
              color: #fff;
              border: @bgcolor;
            }
            &:nth-child(2) {
              background: #f5f5f5;
              color: #333;
              border: #ddd;
            }
          }
        }
      }
    }
  }
  width: 1350px;
  margin: 0 auto;
  height: 860px;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  // 右边
  .Analysis_left {
    /deep/.audioBox {
      // margin: 0 15px;
      margin-top: 10px;
      border-radius: 8px;
      position: relative;
      padding-top: 50px;
      box-shadow: 0px 0px 10px #cfcfcf;
      // border-radius: 8px;
      margin-bottom: 10px;
      .btnback {
        width: 100%;
        background: #fff;
        position: absolute;
        z-index: 50;
        display: flex;
        align-items: center;
        padding: 5px 25px !important;
        padding-bottom: 0 !important;
        // padding-left: 50px !important;

        top: 10px;
        left: 0px;
        img {
          width: 40px !important;
          height: 40px !important;
          cursor: pointer;
          margin: 0;
        }
        div {
          &:nth-child(2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 400px;
            margin: 0 10px;
          }
        }
      }
      audio {
        width: 94%;
        height: 55px;
        // outline: none;
        // display: flex;
        // background:#fff !important;
        // filter: invert(180,90,360); //c3 filter(滤镜) 属性
        // color: red;
        margin-left: 10px;
        border-radius: 10px;
      }

      audio::-webkit-media-controls-enclosure {
        background: rgba(255, 255, 255, 1); //白色底
        /* 滤镜反转为 rgba(0, 162, 255, 1);rgba(255, 93, 0, 0.8)  #7eb637   10,10,10  #757575 */
        border-radius: 0px;
      }
      .media-controls-container,
      .media-controls-container {
        background: #7eb637;
        //滤镜反转为 rgba(0, 162, 255, 1);rgba(255, 93, 0, 0.8)  #7eb637
        // border-radius: 10px;
        // color: red;
      }
      audio::-webkit-media-controls-play-button {
        height: 22px;
        width: 22px;
        min-width: 22px;
        // border-radius: 50%;
        flex-basis: 22px;
        // border-color: red;
        //  background: red;
        // background: #000;
        // color: #4280f4;
        // color: #25c17c;
      }
      // source{
      //   background: red;
      // }
      // 音量隐藏
      audio::-webkit-media-controls-volume-control-container {
        display: none !important;
        // color: red;
        // background: red;
      }

      audio::-webkit-media-controls-current-time-display {
        order: 1; //设置弹性盒对象元素的顺序
        color: #000;
        text-shadow: unset;
      }

      audio::-webkit-media-controls-time-remaining-display {
        order: 2;
        color: #000;
        text-shadow: unset;
      }
    }
    width: 1026px;
    // height: 100%;
    box-shadow: 0 0 10px #cfcfcf;
    height: 830px;
    background: #fff;

    .Analysis_left_main {
      // margin-top: 10px;
      width: 100%;
      background: #fff;
      height: 720px;

      video {
        width: 500px;
        margin: 20px 0;
      }
      audio {
        margin: 20px 0px;
      }
      /deep/ img {
        width: 200px;
        margin: 20px 0px;
      }
      textarea {
        width: 100%;
        padding: 0 10px;
        line-height: 26px;
        color: #333;
        font-size: 16px;
        box-sizing: border-box;
        border: 1px solid #ddd;
        outline: none;
      }
      .allList {
        position: relative;
        padding: 0 30px;

        // .analysis {
        //   //后期删除
        //   height: 200px;
        //   overflow: auto;
        // }
        // .stemBox {
        //   height: 500px;
        //   overflow: auto;
        // }
        .allListLeftborder {
          width: 4px;
          height: 16px;
          display: inline-block;
          background: #df3833;
          position: absolute;
          left: -30px;
        }

        .allListTitle {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #333;
          padding: 18px 0;
          // background: #df3833;
          > div {
            &:nth-child(2) {
              padding-top: 6px;
            }
            &:nth-child(3) {
              padding-top: 2px;
              margin: 0 10px;
            }
            //  &:nth-child(4) {
            //   padding-top: 4px;
            // }
          }
        }

        .singleChoice,
        .multipleChoice,
        .judgesChoice,
        .blankChoice,
        .readingChoice,
        .questionsChoice,
        .allListTitle {
          .allListTitle {
            font-size: 16px;
            color: #000;
            font-weight: bold;
          }
          .singleScreen {
            // 单屏幕
            background: #fff;
            height: 658px;
            width: 980px;
            padding: 0 10px;
            overflow: auto;
            &::-webkit-scrollbar  {
              width: 5px;
              height: 0px;
            }
              &::-webkit-scrollbar-thumb  {
              border-radius: 5px;
              // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
              background: #999;
            }
              &::-webkit-scrollbar-track  {
              // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
              border-radius: 5px;
              background: #eee;
            }
          }
          .analysis {
            .analysisDa {
              // display: flex;
              margin: 40px 0;
              // align-items: center;
              .nide {
                margin-right: 60px;

                .youanswer {
                  display: flex;
                  align-items: center;
                  color: #4280f4;
                }
              }
              .daan {
                .single {
                  height: 23px;
                  line-height: 23px;
                  color: #25c17c;
                }
              }
            }
          }
          .jiexi {
            font-size: 14px;

            div {
              &:first-child {
                font-size: 16px;
              }
            }
          }
        }
      }
      .singleChoice,
      .multipleChoice,
      .judgesChoice {
        .analysisDa {
          width: 100%;
          min-height: 160px;
          border: 1px solid #ccc;
          margin-top: 18px;
          border-radius: 10px;

          > div {
            &:first-child {
              display: flex;
              padding: 19px 0 0 15px;
              .daan {
                margin-left: 104px;
                > div {
                  &:first-child {
                    color: #000;
                  }
                }
              }
            }
          }
          .jiexi {
            padding: 24px 15px;
          }
        }
      }
      .singleChoice {
        //单选题
        .singleStem {
          padding: 15px 0 11px;
        }
        .singleSelectList {
          padding: 5px 0 5px 10px;
          line-height: 24px;
          cursor: pointer;
          display: flex;
          align-items: center;
          div {
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            background: #eee;
            text-align: center;
            margin-right: 10px;
            border-radius: 50%;
            &.isSelect {
              background: rgba(255, 56, 51, 0.3);
              color: #fff;
            }
          }
        }
      }
      .multipleChoice {
        //多选题
        .multipleStem {
          padding: 15px 0 11px;
        }
        .multipleSelectList {
          padding: 5px 0 5px 10px;
          line-height: 24px;
          cursor: pointer;
          display: flex;
          align-items: center;
          div {
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            background: #eee;
            text-align: center;
            margin-right: 10px;
            border-radius: 50%;
            &.isSelect {
              background: rgba(255, 56, 51, 0.3);
              color: #fff;
            }
          }
        }
      }
      .judgesChoice {
        //判断题
        .judgesStem {
          padding: 15px 0 11px;
        }
        .judgesSelectList {
          padding: 5px 0 5px 10px;
          line-height: 24px;
          cursor: pointer;
          display: flex;
          align-items: center;
          div {
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            background: #eee;
            text-align: center;
            margin-right: 10px;
            border-radius: 50%;
            &.isSelect {
              background: rgba(255, 56, 51, 0.3);
              color: #fff;
            }
          }
        }
      }
      .blankChoice {
        .blankWords,
        .blankAudio {
          background: #fff;
          padding: 34px 12px;
          .analysis {
            .daan {
              > div {
                width: 100%;
                max-height: 160px;
                border: 1px solid #ccc;
                margin-bottom: 20px;
                padding: 15px 34px;
                border-radius: 10px;

                &:first-child {
                  margin-top: 14px;
                }
                > div {
                  &:first-child {
                    // display: flex;
                    font-size: 16px;
                    .blankChoice-answer {
                      // margin-left: 36px;
                    }
                  }
                  &:nth-child(2) {
                    margin-top: 6px;
                  }
                }
              }
              .youanswer {
                display: flex;
                align-items: center;
                // font-weight: bold;
                font-size: 16px;
                margin-bottom: 6px;
                margin-top: 6px;
                span {
                  color: #4280f4;
                }
              }
              .reference {
                span {
                  height: 23px;
                  line-height: 23px;
                  color: #25c17c;
                }
              }
               .reference1 {
                display: flex;
                div {
                  color: #25c17c;
                }
              }
              .resolve {
                font-size: 14px;
                span {
                  // font-weight: bold;
                  font-size: 16px;
                }
              }
            }
            .bigjiexi {
              margin-top: 40px;
              width: 100%;
              min-height: 60px;
              border: 1px solid #ccc;
              border-radius: 10px;
              padding: 15px 34px;
            }
          }
          //多元填空
          .blankStem {
            // padding: 15px 0 11px;
            line-height: 30px;
          }
        }
        /deep/input {
          color: #2d56b1;
          border: none;
          border-bottom: 1px solid #333;
          width: 180px;
          padding: 0 5px;
          text-align: center;
          font-size: 14px;
          // background: #f8f8f8;
        }
        // /deep/span {
        //   background: #f9f9f9 !important;
        // }
      }
      // 阅读题
      .readingChoice {
        .readingVideo,
        .readingAudio,
        .readingWords {
          .stemBox {
            .border {
              width: 1px;
              background: #dddddd;
            }
          }

          .readingStem {
            margin-top: 20px;
            line-height: 30px;
          }
          .readTopic {
            margin-bottom: 40px;
            &:first-child {
              margin-top: 20px;
            }
            .readingjiexi {
              width: 100%;
              min-height: 120px;
              margin-bottom: 20px;
              border: 1px solid #ccc;
              border-radius: 10px;
              padding: 15px;
              overflow: auto;
              .readingstyle {
                display: flex;
                // padding: 15px;
                .nide {
                  margin-right: 40px;
                  .youanswer {
                    color: #4280f4;

                    display: flex;
                    align-items: center;
                    i {
                      margin-left: 10px;
                    }
                  }
                }

                .daan {
                  margin-left: 104px;
                  color: #25c17c;
                  .reference {
                    margin-top: 4px;
                  }
                }
              }
              .sortjiexi {
                padding-top: 15px;
                font-size: 14px;

                span {
                  font-size: 14px;

                  &:first-child {
                    font-size: 16px;
                  }
                }
              }
            }
          }
          .jiexi {
            width: 100%;
            border: 1px solid #ccc;
            min-height: 60px;
            border-radius: 8px;
            padding: 15px;
          }
        }
        //   background: #f9f9f9;
        //   padding: 34px 12px;
        // }
        position: relative;
        .card {
          position: absolute;
          left: 520px;
          display: flex;
          align-items: center;
          .allListLeftborder {
            top: 4px;
          }
        }
        .singleScreen {
          // 单屏幕
          background: #fff;
          height: 658px;
          width: 980px;
          .stemBox {
            display: flex;
            justify-content: space-between;
            .stem {
              width: 488px;
              padding: 0 10px;
              overflow: auto;
              height: 658px;
              border-right: 32px solid #fff;
              &::-webkit-scrollbar  {
                width: 5px;
                height: 0px;
              }
                &::-webkit-scrollbar-thumb  {
                border-radius: 5px;
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                background: #999;
              }
                &::-webkit-scrollbar-track  {
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                border-radius: 5px;
                background: #eee;
              }
            }
            .border {
              width: 1px;
              background: #dddddd;
            }
            .selectList {
              width: 488px;
              overflow: auto;

              .readTopic {
                margin-top: 10px;
                padding-bottom: 20px;
                border-bottom: 1px solid #ccc;
                &:last-child {
                  border: none;
                }
              }
              &::-webkit-scrollbar  {
                width: 5px;
                height: 0px;
              }
                &::-webkit-scrollbar-thumb  {
                border-radius: 5px;
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                background: #999;
              }
                &::-webkit-scrollbar-track  {
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                border-radius: 5px;
                background: #eee;
              }
              height: 658px;
              border-left: 32px solid #fff;
              padding: 0 10px;
            }
          }
        }
        //阅读题

        .readingSelectList {
          padding: 5px 0 5px 10px;
          line-height: 30px;
          cursor: pointer;
          display: flex;
          p {
            width: 380px;
          }
          div {
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            background: #eee;
            text-align: center;
            margin-right: 10px;
            border-radius: 50%;
            &.isSelect {
              background: rgba(255, 56, 51, 0.3);
              color: #fff;
            }
          }
        }
      }
      .questionsChoice,
      .listeningChoice {
        .nide-answer {
          > div {
            &:first-child {
              width: 100%;
              min-height: 92px;
              margin-bottom: 20px;
              border: 1px solid #ccc;
              padding: 15px;

              border-radius: 8px;
            }
            &:nth-child(2) {
              width: 100%;
              min-height: 92px;

              border: 1px solid #ccc;
              padding: 15px 15px;
              border-radius: 10px;
              &::-webkit-scrollbar  {
                width: 5px;
                height: 0px;
              }
                &::-webkit-scrollbar-thumb  {
                border-radius: 5px;
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                background: #999;
              }
                &::-webkit-scrollbar-track  {
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                border-radius: 5px;
                background: #eee;
              }
            }
          }
        }
      }
      .questionsChoice {
        //问答题
/deep/table {
            width: 420px !important;
            overflow-x: auto;
            td {
              border-bottom: 1px solid #000 !important;

              border-right: 1px solid #000 !important;
              border-top: none !important;
              border-left: none !important;
            }

            border-top: 1px solid #000 !important;

            border-left: 1px solid #000 !important;

            border-spacing: 0 !important; /*去掉单元格间隙*/
          }
        position: relative;
        .singleScreen {
          .questionsWords,
          .questionsAudio,
          .questionsVideo,
          .questionsVideo {
            .stemBox {
              .selectList {
                .analysis {
                  .nide-answer {
                    // padding-right: 20px;
                    /deep/table {
            width: 400px !important;}
                    .youanswer {
                      color: #4280f4;
                      margin-bottom: 20px;
                      // margin-left: 10px;
                    }
                    .solution {
                      // margin-top: 20px;
                      line-height: 30px;
                      font-size: 14px;
                    }
                  }
                }
              }
              .stem {
                .questionsStem {
                  line-height: 30px;
                }
              }
            }
          }
        }
        .card {
          position: absolute;
          left: 511px;
          display: flex;
          align-items: center;
          .allListLeftborder {
            top: 4px;
          }
        }
        textarea {
          width: 100%;
          height: 200px;
          resize: none;
          border-radius: 8px;
          padding: 10px 20px;
        }
        .singleScreen {
          // 单屏幕
          background: #fff;
          height: 658px;
          width: 980px;
          .stemBox {
            display: flex;
            justify-content: space-between;
            .stem {
              width: 488px;
              padding: 10px 10px;
              overflow: auto;
              &::-webkit-scrollbar  {
                width: 5px;
                height: 0px;
              }
                &::-webkit-scrollbar-thumb  {
                border-radius: 5px;
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                background: #999;
              }
                &::-webkit-scrollbar-track  {
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                border-radius: 5px;
                background: #eee;
              }
              height: 658px;
              border-right: 32px solid #fff;
            }
            .border {
              width: 1px;
              background: #dddddd;
            }
            .selectList {
              width: 488px;
              overflow: auto;
              &::-webkit-scrollbar  {
                width: 5px;
                height: 0px;
              }
                &::-webkit-scrollbar-thumb  {
                border-radius: 5px;
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                background: #999;
              }
                &::-webkit-scrollbar-track  {
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                border-radius: 5px;
                background: #eee;
              }
              height: 658px;
              border-left: 32px solid #fff;
              padding: 10px 10px;
            }
          }
        }
      }
      .listeningChoice {
        //听力题
/deep/table {
            width: 420px !important;
            overflow-x: auto;
            td {
              border-bottom: 1px solid #000 !important;

              border-right: 1px solid #000 !important;
              border-top: none !important;
              border-left: none !important;
            }

            border-top: 1px solid #000 !important;

            border-left: 1px solid #000 !important;

            border-spacing: 0 !important; /*去掉单元格间隙*/
          }
        position: relative;
        .card {
          position: absolute;
          left: 511px;
          display: flex;
          align-items: center;
          .allListLeftborder {
            top: 4px;
          }
        }
        textarea {
          width: 100%;
          height: 200px;
          resize: none;
          border-radius: 8px;
        }
        .singleScreen {
          // 单屏幕
          background: #fff;
          height: 658px;
          width: 980px;
          .stemBox {
            display: flex;
            justify-content: space-between;
            .stem {
              width: 488px;
              padding: 10px 10px;
              overflow: auto;
              &::-webkit-scrollbar  {
                width: 5px;
                height: 0px;
              }
                &::-webkit-scrollbar-thumb  {
                border-radius: 5px;
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                background: #999;
              }
                &::-webkit-scrollbar-track  {
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                border-radius: 5px;
                background: #eee;
              }
              height: 658px;
              border-right: 32px solid #fff;
            }
            .border {
              width: 1px;
              background: #dddddd;
            }
            .selectList {
              width: 488px;
              overflow: auto;
              &::-webkit-scrollbar  {
                width: 5px;
                height: 0px;
              }
                &::-webkit-scrollbar-thumb  {
                border-radius: 5px;
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                background: #999;
              }
                &::-webkit-scrollbar-track  {
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                border-radius: 5px;
                background: #eee;
              }
              height: 658px;
              border-left: 32px solid #fff;
              padding: 10px 10px;
              .analysis {
                .youanswer {
                  color: #4280f4;
                  margin-bottom: 20px;
                  margin-left: 10px;
                }
                .solution {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
  .Analysis_left_bottom {
    height: 94px;
    background: #fff;

    // justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .button {
      display: flex;
      width: 290px;
      margin: auto;
      line-height: 94px;
      div {
        &:nth-child(2) {
          button {
            background: #e03530;
            color: #fff;
            border-radius: 0px;
            border-color: #e03530;
          }
        }
        &:nth-child(1) {
          button {
            border-radius: 4px 0 0 4px;
            border-right: none;
            span {
              margin-right: 10px;
            }
          }
        }
        &:nth-child(3) {
          button {
            border-radius: 0px 4px 4px 0px;
            border-left: none;
            span {
              margin-left: 10px;
            }
          }
        }
      }
      .el-button {
        color: #717786;
        border: solid 1px #ccc;
        background: none;
      }
    }
  }
}

// 左边
.Analysis_right {
  height: 100%;
  width: 300px;
  // background: #fff;
  .testname {
    width: 100%;
    background: #fff;
    text-align: center;
    // height: 50px;
    // padding-top: 10px;
    padding: 20px 0 0px 0;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 0px 10px #cfcfcf;
    > div {
      display: flex;
      padding: 15px 20px;
      justify-content: space-between;
      p {
        font-size: 14px;
        color: #999;
      }
      > div {
        display: flex;
        /deep/.el-rate {
          .el-rate__icon {
            margin-right: 0px;
          }
        }
      }
    }
    > p {
      font-size: 16px;
      font-weight: bold;
      color: #000;
    }
  }
  button {
    // margin: 0 20px 15px;
    background: #df3833;
    color: #fff;
    font-size: 16px;
    border-radius: 6px;
    text-align: center;
    width: 100%;
    cursor: pointer;
  }
  .Analysis_right_top {
    padding: 17px 20px;
    background: #fff;
    border-radius: 8px;
    > div {
      &:nth-child(2) {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      .num {
        line-height: 12px;
        font-size: 12px;
        color: #999;
      }
    }
    // button {
    //   // margin: 0 20px 15px;
    //   background: #df3833;
    //   color: #fff;
    //   font-size: 16px;
    //   border-radius: 6px;
    //   text-align: center;
    //   width: 260px;
    //   cursor: pointer;
    //   padding: 50px 0;
    // }
    > div {
      &:nth-child(1) {
        text-align: center;
        line-height: 30px;
      }
    }
    .speed {
      width: 100%;
      height: 10px;
      border-radius: 5px;
      overflow: hidden;
      background-color: #f5f5f5;
      margin-right: 10px;
      //
      div {
        height: 100%;
        width: 0;
        background: rgb(255, 56, 51);
      }
    }
    .time {
      padding: 15px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .countDown {
        /deep/ .van-count-down {
          font-size: 24px;
          color: #666;
          line-height: 40px;
          padding: 0 15px 0 12px;
          border-right: 1px solid #eee;
          margin-right: 8px;
        }
      }
      .timeDown {
        display: flex;
        > div {
          width: 30px;
          height: 30px;
          &:nth-child(1) {
            margin: 5px 12px;
            background: url(../../assets/image/013.png) no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
          }
          &:nth-child(2) {
            margin: 5px 12px;
            background: url(../../assets/image/014.png) no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
  .Analysis_right_main {
    margin-top: 18px;
    height: 670px;
    background: #fff;
    padding: 20px 0 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px #cfcfcf;
    .Analysis_right_main_title {
      padding-left: 20px;
      height: 20px;
      font-size: 16px;

      color: #000;
      font-weight: bold;
      line-height: 20px;
      border-left: 5px solid #df3833;
      // margin: 0 20px;
      padding-bottom: 10px;
    }
    > ul {
      padding: 20px;
      display: flex;
      // justify-content: space-between;
      li {
        margin: 0 10px 0 0;
        position: relative;
        i {
          width: 16px;
          height: 16px;
          margin-right: 6px;
          border-radius: 4px;
          display: inline-block;
          border: 1px solid #ccc;
          vertical-align: sub;
          box-sizing: border-box;
        }
        .index__yizuo {
          background: rgba(255, 56, 51, 0.3);
          border: none;
        }
        .index__weizuo {
          background: #fff;
        }
        .index__biaoji {
          border: 1px solid #ccc;
          background: #fff;
        }
        .index__biaojixi {
          background: url("../../assets/image/16.png") no-repeat 0 0;
          position: absolute;
          left: 0;
          top: 5px;
          border: none;
        }
      }
      // li {
      //   display: block;
      //   margin-right: 10px;
      //   font-size: 12px;
      //   // line-height: 16px;
      //   color: #666;
      //   width: 82px;
      //   height: 45px;
      //   box-sizing: border-box;
      //   border: 1px solid;
      //   // padding: 5px;
      //   // padding-right: 0;
      //   border-radius: 2px;
      //   justify-content: space-around;
      //   justify-items: center;
      //   align-items: center;

      //   .text_done_right {
      //     flex: 1;
      //     p {
      //       font-size: 12px;
      //       color: #000;
      //       &:nth-child(2) {
      //         font-size: 12px;
      //         color: #a5a1a5;
      //       }
      //     }
      //   }

      //   // text-align: center;
      //   // line-height: 43px;
      //   display: flex;
      //   justify-content: space-around;
      //   i {
      //     // width: 16px;
      //     // height: 16px;
      //     margin-right: 6px;
      //     border-radius: 4px;
      //     display: inline-block;
      //     // border: 1px solid #ccc;
      //     vertical-align: sub;
      //     box-sizing: border-box;
      //     img {
      //       width: 19px;
      //       height: 18px;
      //       object-fit: cover;
      //     }
      //   }
      //   &:nth-child(1) {
      //     border-color: #f19d81;
      //     background: #ffefe7;
      //   }
      //   &:nth-child(2) {
      //     border-color: #c8e9ff;
      //     background: #f0faff;
      //   }
      //   &:nth-child(3) {
      //     border-color: #dedede;
      //     background: #fff;
      //   }
      // }
    }

    .index__BigtiNumberBox {
      margin: 0 10px 0 20px;
      .stem {
        margin: 20px 0;
      }
      ul {
        max-height: 520px;
        overflow: auto;
        &::-webkit-scrollbar  {
          width: 5px;
          height: 0px;
        }
          &::-webkit-scrollbar-thumb  {
          border-radius: 5px;
          // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
          background: #999;
        }
          &::-webkit-scrollbar-track  {
          // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
          border-radius: 5px;
          background: #eee;
        }
        display: flex;
        flex-wrap: wrap;
        margin-left: 8px;
        &::-webkit-scrollbar  {
          width: 5px;
          height: 0px;
        }
          &::-webkit-scrollbar-thumb  {
          border-radius: 5px;
          // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
          background: #999;
        }
          &::-webkit-scrollbar-track  {
          // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
          border-radius: 5px;
          background: #eee;
        }
      }
      li {
        display: inline-block;
        padding: 0 !important;
        font-size: 12px;
        position: relative;
        width: 23px;
        height: 23px;
        line-height: 23px !important;
        color: #333;
        text-align: center;
        border: 1px solid #ccc;
        background: #fff;
        margin: 0 18px 20px 0;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        &.active {
          background: #f19d81;
          border: none;
        }
        &.act {
          border-color: #de3833;
          color: #df3833;
        }
      }
    }
  }
}
</style>